<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="inputs" :reactiveVar="reactiveVar">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="inputs"
        :reactiveVar="reactiveVar"
        :numberOfCols="3"
        @submit-form="getDataShukkaHikiWatashiItemsActions"
      >
      </base-form>
    </base-expansion-panel>
    <base-table
      :headers="shukkaHikiWatashiList.headers"
      :items="shukkaHikiWatashiItems"
      :columnName="[
        'item.bikou_list',
        'item.status_name',
        'item.comment_count',
        'item.total_weight',
        'item.shukkaShorui',
        'item.operator_documents',
        'item.picking_completed_flag',
        'item.arms_shipment_process_confirmed_flag',
        'item.center_documents',
        'item.center_attached_docs_confirmed_flag',
        'item.shipped_confirmed_flag',
        'item.tracking_number',
        'item.edit',
        'item.shipping_label_link',
        'item.shukkaShoruiPrint',
      ]"
      :itemClass="itemClass"
      :itemKey="itemKey"
      @row-selected="
        (selected) => {
          this.rowSelected = selected;
        }
      "
      multiSort
      showSelect
      minWidth="2200"
    >
      <template v-slot:[`item.bikou_list`]="{ item }">
        <v-textarea
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.bikou_list"
          @change="
            editShukkaHikiWatashiItemsActions([
              {
                shipping_seq_number: item.shipping_seq_number,
                new_bikou_list: $event,
              },
            ])
          "
        ></v-textarea>
        <div v-else-if="item.bikou_list" style="min-width: 100px">
          <span style="line-break: anywhere" v-for="ele in item.bikou_list.split('\n')" :key="ele"
            >{{ ele }}<br
          /></span>
        </div>
      </template>
      <template v-slot:[`item.total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_weight != null">{{ item.total_weight.commaString(' kg') }}</span>
        </td>
      </template>
      <template v-slot:[`item.status_name`]="{ item }">
        <span v-if="item.status === 9">出荷済</span>
        <span v-else>{{ item.status_name }}</span>
      </template>
      <template v-slot:[`item.comment_count`]="{ item }">
        <lazy>
          <v-btn
            color="primary"
            x-small
            align="center"
            justify="center"
            @click="
              getDataShukkaHikiWatashiCommentActions([item.shipping_seq_number]);
              rowComment = item.shipping_seq_number;
              showModal = true;
            "
            >開く</v-btn
          >
        </lazy>
        <div align="center" justify="center">
          <div class="comment-text">
            {{ item.comment_count ? item.comment_count + '件' : '' }}
            {{ item.last_comment_date }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.shukkaShorui`]="{ item }">
        <lazy>
          <div class="d-flex justify-center align-center">
            <v-btn
              v-if="!item.editing"
              color="primary"
              x-small
              @click="
                downloadMergedExcels({
                  format: 'url',
                  shippingSeqNumbers: [item.shipping_seq_number],
                  attachmentsIncluded: true,
                })
              "
            >
              DL
            </v-btn>
            <tool-tip
              v-if="item.no_battery_img_models && item.no_battery_img_models.length > 0"
              :message="item.no_battery_img_models?.join(',<br>')"
              classes="mx-2"
            ></tool-tip>
          </div>
        </lazy>
      </template>
      <template v-slot:[`item.shukkaShoruiPrint`]="{ item }">
        <lazy>
          <v-btn
            x-small
            color="primary"
            @click="
              downloadPrintExcel({
                shippingSeqNumbers: [item.shipping_seq_number],
              })
            "
            >印刷</v-btn
          >
        </lazy>
      </template>
      <template v-slot:[`item.shipping_label_link`]="{ item }">
        <lazy>
          <v-btn
            v-if="item.shipping_label_link"
            color="primary"
            x-small
            @click="downloadFile({ key: item.shipping_label_link })"
            >dl</v-btn
          >
        </lazy>
      </template>
      <template v-slot:[`item.operator_documents`]="{ item }">
        <div v-if="role">
          <lazy>
            <div>
              <v-btn
                v-if="!item.editing"
                x-small
                color="primary"
                @click="
                  uploadOperatorDocumentsModal = true;
                  uploadingRow = item;
                "
                >UP
              </v-btn>
              <div v-if="!!item.operator_documents" class="text-caption">
                {{
                  !item.operator_documents
                    ? ''
                    : item.operator_documents.length > 0
                      ? 'x' + item.operator_documents.length
                      : ''
                }}
              </div>
            </div>
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.picking_completed_flag`]="{ item }">
        <v-row>
          <v-col class="d-flex justify-center">
            <lazy>
              <v-checkbox
                v-if="item.editing"
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.picking_completed_flag"
                dense
                hide-details
                :key="item.picking_completed_flag"
                @change="
                  editShukkaHikiWatashiItemsActions([
                    {
                      shipping_seq_number: item.shipping_seq_number,
                      new_picking_completed_flag: $event,
                    },
                  ])
                "
              ></v-checkbox>
              <v-checkbox
                v-else
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.picking_completed_flag"
                dense
                hide-details
                disabled
              ></v-checkbox>
            </lazy>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.arms_shipment_process_confirmed_flag`]="{ item }">
        <v-row>
          <v-col class="d-flex justify-center">
            <lazy>
              <v-checkbox
                v-if="item.editing"
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.arms_shipment_process_confirmed_flag"
                dense
                hide-details
                :key="item.arms_shipment_process_confirmed_flag"
                @change="
                  editShukkaHikiWatashiItemsActions([
                    {
                      shipping_seq_number: item.shipping_seq_number,
                      new_arms_shipment_process_confirmed_flag: $event,
                    },
                  ])
                "
              ></v-checkbox>
              <v-checkbox
                v-else
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.arms_shipment_process_confirmed_flag"
                dense
                hide-details
                disabled
              ></v-checkbox>
            </lazy>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.center_documents`]="{ item }">
        <div v-if="role">
          <lazy>
            <div>
              <v-btn
                color="primary"
                v-if="!item.editing"
                x-small
                @click="
                  uploadCenterDocumentsModal = true;
                  uploadingRow = item;
                "
                >up</v-btn
              >
              <div v-if="!!item.center_documents" class="text-caption">
                {{
                  !item.center_documents
                    ? ''
                    : item.center_documents.length > 0
                      ? 'x' + item.center_documents.length
                      : ''
                }}
              </div>
            </div>
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.center_attached_docs_confirmed_flag`]="{ item }">
        <v-row>
          <v-col class="d-flex justify-center">
            <lazy>
              <v-checkbox
                v-if="item.editing"
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.center_attached_docs_confirmed_flag"
                dense
                hide-details
                :key="item.center_attached_docs_confirmed_flag"
                @change="
                  editShukkaHikiWatashiItemsActions([
                    {
                      shipping_seq_number: item.shipping_seq_number,
                      new_center_attached_docs_confirmed_flag: $event,
                    },
                  ])
                "
              ></v-checkbox>
              <v-checkbox
                v-else
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.center_attached_docs_confirmed_flag"
                dense
                hide-details
                disabled
              ></v-checkbox>
            </lazy>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.shipped_confirmed_flag`]="{ item }">
        <v-row>
          <v-col class="d-flex justify-center">
            <lazy>
              <v-checkbox
                v-if="item.editing"
                :disabled="item.status !== 6"
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.shipped_confirmed_flag"
                dense
                hide-details
                :key="item.shipped_confirmed_flag"
                @change="
                  editShukkaHikiWatashiItemsActions([
                    {
                      shipping_seq_number: item.shipping_seq_number,
                      new_shipped_confirmed_flag: $event,
                    },
                  ])
                "
              ></v-checkbox>
              <v-checkbox
                v-else
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.shipped_confirmed_flag"
                dense
                hide-details
                disabled
              ></v-checkbox>
            </lazy>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.tracking_number`]="{ item }">
        <v-textarea
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.tracking_number"
          @change="
            editShukkaHikiWatashiItemsActions([
              {
                shipping_seq_number: item.shipping_seq_number,
                new_tracking_number: $event,
              },
            ])
          "
        ></v-textarea>
        <span v-else>{{ item.tracking_number }}</span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <lazy>
          <div v-if="role">
            <v-btn
              v-if="item.editing"
              text
              icon
              color="green lighten-2"
              @click="
                updateShukkaHikiWatashiItemsActions([
                  {
                    shippingSeqNumber: item.shipping_seq_number,
                    bikou: item.new_bikou_list,
                    pickingCompletedFlag: item.new_picking_completed_flag,
                    armsConfirmedFlag: item.new_arms_shipment_process_confirmed_flag,
                    centerConfirmedFlag: item.new_center_attached_docs_confirmed_flag,
                    shippedConfirmedFlag: item.new_shipped_confirmed_flag,
                    shippingCheckUserName: item.shipping_check_user_name,
                    shippingDatetime: item.shipping_datetime,
                    trackingNumber: item.new_tracking_number,
                    memberNo: item.member_id,
                  },
                ])
              "
            >
              <v-icon large>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              v-else
              :disabled="item.status === 9"
              text
              icon
              color="blue lighten-2"
              @click="
                editShukkaHikiWatashiItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    editing: true,
                  },
                ])
              "
            >
              <v-icon large>mdi-pencil-box</v-icon>
            </v-btn>
            <v-btn
              v-if="item.editing"
              text
              icon
              color="red lighten-2"
              @click="
                editShukkaHikiWatashiItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    editing: false,
                    new_bikou_list: item.bikou_list,
                    new_picking_completed_flag: item.picking_completed_flag,
                    new_arms_shipment_process_confirmed_flag: item.arms_shipment_process_confirmed_flag,
                    new_center_attached_docs_confirmed_flag: item.center_attached_docs_confirmed_flag,
                    new_shipped_confirmed_flag: item.shipped_confirmed_flag,
                    new_tracking_number: item.tracking_number,
                  },
                ])
              "
            >
              <v-icon large>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </lazy>
      </template>
    </base-table>
    <commentModal
      :show-modal="showModal"
      :items="commentItems"
      :role="role"
      @close="closeModal"
      @commentRegist="commentRegist"
    >
    </commentModal>
    <v-card-actions class="justify-center mb-3">
      <v-btn
        :disabled="rowSelected.length === 0"
        color="teal darken-2 white--text"
        @click="
          downloadMergedExcels({
            format: 'url',
            shippingSeqNumbers: rowSelected.map((row) => row.shipping_seq_number),
          })
        "
        >{{ labels.BTN_SHUKKA_SHORUI_DL }}
        <v-icon size="22">mdi-arrow-down-bold</v-icon>
      </v-btn>
      <v-btn
        :disabled="rowSelected.length === 0"
        color="primary"
        @click="
          downloadPrintExcel({
            shippingSeqNumbers: rowSelected.map((row) => row.shipping_seq_number),
          })
        "
        >一括印刷用Excel DL
        <v-icon size="22">mdi-printer</v-icon>
      </v-btn>
      <v-btn color="success darken-1" :disabled="rowSelected.length === 0" @click="downloadPreparedShipmentListCSV"
        >センター出荷準備リスト出力 <v-icon size="22">mdi-arrow-down-bold</v-icon></v-btn
      >
      <csv-result-dl
        :disabled="shukkaHikiWatashiItems.length === 0"
        :item-key="itemKey"
        :row-selected="rowSelected"
      ></csv-result-dl>
    </v-card-actions>
    <UploadOperatorDocumentsModal :parent="$data" />
    <UploadCenterDocumentsModal :parent="$data" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { inputs } from '../consts';
export default {
  components: {
    commentModal: () => import(/* webpackChunkName: "commentModal" */ '@/components/ui/commentModal.vue'),
    UploadOperatorDocumentsModal: () =>
      import(/* webpackChunkName: "UploadOperatorDocumentsModal" */ '@/components/ui/UploadOperatorDocumentsModal.vue'),
    UploadCenterDocumentsModal: () =>
      import(/* webpackChunkName: "UploadCenterDocumentsModal" */ '@/components/ui/UploadCenterDocumentsModal.vue'),
    lazy: () => import(/* webpackChunkName: "Lazy" */ '@/components/ui/Lazy.vue'),
    ToolTip: () => import(/* webpackChunkName: "toolTip" */ '../components/ui/ToolTip.vue'),
  },
  name: 'ShukkaJunbiHikiwatashi',
  data() {
    return {
      itemKey: 'shipping_seq_number',
      showModal: false,
      rowSelected: [],
      rowComment: '',
      uploadOperatorDocumentsModal: null,
      uploadCenterDocumentsModal: null,
      uploadingRow: null,
      role: false,
      reactiveVar: {
        shukkayoteiDate: [],
        shukkatoshiNo: '',
        ankenNaiyo: '',
        buyerName: '',
        status: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      shukkaHikiWatashiList: 'defined/shukkaJunbiHikiwatashi',
      shukkaHikiWatashiItems: 'shukkaJunbiHikiWatashi/shukkaHikiWatashiItems',
      commonSearchConditions: 'common/searchConditions',
      labels: 'defined/labels',
      statusItems: 'defined/statusItems',
      commentItems: 'shukkaJunbiHikiWatashi/commentItems',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),
    searchConditions() {
      return this.commonSearchConditions?.shukkaJunbiHikiWatashi ?? this.reactiveVar;
    },
    inputs() {
      return inputs.shukkaJunbiHikiwatashi(this.statusItems.filter((i) => ['5', '6'].includes(i.value)));
    },
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.SHUKKA_JUNBI_HIKIWATASHI,
      authority: this.user.authority,
    });
    this.reactiveVar = this.searchConditions;
  },
  methods: {
    ...mapActions({
      getDataShukkaHikiWatashiItemsActions: 'shukkaJunbiHikiWatashi/getDataShukkaHikiWatashiItemsActions',
      getDataShukkaHikiWatashiCommentActions: 'shukkaJunbiHikiWatashi/getDataShukkaHikiWatashiCommentActions',
      editShukkaHikiWatashiItemsActions: 'shukkaJunbiHikiWatashi/editShukkaHikiWatashiItemsActions',
      updateShukkaHikiWatashiItemsActions: 'shukkaJunbiHikiWatashi/updateShukkaHikiWatashiItemsActions',
      registCommentActions: 'shukkaJunbiHikiWatashi/registCommentActions',
      downloadMergedExcels: 'api/downloadMergedExcels',
      getRole: 'common/getRole',
      downloadFile: 'api/downloadFile',
      downloadPrintExcel: 'api/downloadPrintExcel',
      apiDownloadPreparedShipmentListCSV: 'api/downloadPreparedShipmentListCSV',
    }),
    ...mapMutations({
      setReactiveVarStatus: 'shukkaJunbiHikiWatashi/setReactiveVarStatus',
    }),
    async commentRegist(val, noticeTeams = []) {
      await this.registCommentActions({
        comment: val,
        noticeTeams: noticeTeams,
        shippingSeqNumber: this.rowComment,
      });
      this.getDataShukkaHikiWatashiItemsActions(this.reactiveVar);
      this.closeModal();
    },
    async downloadPreparedShipmentListCSV() {
      await this.apiDownloadPreparedShipmentListCSV({
        shippingSeqNumbers: this.rowSelected.map((row) => row.shipping_seq_number),
      });
    },
    closeModal() {
      this.rowComment = '';
      this.commentItems = [];
      this.showModal = false;
    },
    itemClass(item) {
      if (item.editing) {
        return 'highlight-green not-change-hover-color';
      }
      return '';
    },
  },
  watch: {
    searchConditions(newVal) {
      this.reactiveVar = newVal;
    },
  },
};
</script>
<style>
.wrap-text {
  white-space: pre;
}
.comment-text {
  font-size: xx-small;
}
</style>
